const Accordion = {
  parts: ["panel", "button"],
  baseStyle: {
    panel: {
      py: 8,
    },
    button: {
      bg: "#E3E6EA",
      color: "primary.700",
      fontSize: "lg",
      fontWeight: 500,
      shadow: "0 -1px 0 1px white",
    },
  },
};

export default Accordion;
