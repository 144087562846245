import { lazy } from "react";

import { ReactComponent as HomeIcon } from "./assets/svgs/house.svg";
import { ReactComponent as TruckIcon } from "./assets/svgs/truck.svg";
import { ReactComponent as ClipboardIcon } from "./assets/svgs/clipboard.svg";
import { ReactComponent as WarningIcon } from "./assets/svgs/warning.svg";
import { ReactComponent as CalendarIcon } from "./assets/svgs/calendar.svg";
import { ReactComponent as BackArrowIcon } from "./assets/svgs/fatArrowLeft.svg";
import { ReactComponent as WrenchIcon } from "./assets/svgs/wrench.svg";
import { ReactComponent as BellIcon } from "./assets/svgs/bell.svg";
import { ReactComponent as PickupTruckIcon } from "./assets/svgs/pickup.svg";
import { ReactComponent as ClockwiseArrowIcon } from "./assets/svgs/arrows-clockwise.svg";
import { ReactComponent as ClockIcon } from "./assets/svgs/clock.svg";
import { ReactComponent as CogIcon } from "./assets/svgs/cog.svg";
import { ReactComponent as PumpIcon } from "./assets/svgs/pump.svg";
import { ReactComponent as CartIcon } from "./assets/svgs/shopping-cart.svg";
import { ReactComponent as QuestionIcon } from "./assets/svgs/question.svg";

const Dashboard = lazy(() => import("./views/dashboard"));
const Menu = lazy(() => import("./views/menu"));
const Account = lazy(() => import("./views/account"));
const Admin = lazy(() => import("./views/admin"));

const VehiclePreUseChecks = lazy(() => import("./views/preUseChecks"));
const DefectManagement = lazy(() => import("./views/defects"));
const ScheduledMaintenance = lazy(() => import("./views/maintenance"));
const Recalls = lazy(() => import("./views/recalls"));
const Workshop = lazy(() => import("./views/workshop"));
const Breakdowns = lazy(() => import("./views/breakdowns"));
const ShortTermHires = lazy(() => import("./views/shortTermHires"));
const Recharges = lazy(() => import("./views/recharges"));
const Fuel = lazy(() => import("./views/fuel"));

// Briefs
const Briefs = lazy(() => import("./views/briefs"));
const Brief = lazy(() => import("./views/briefs/brief"));

// Fleet and assets
const Fleet = lazy(() => import("./views/fleet"));
const Asset = lazy(() => import("./views/asset"));
const AssetInfo = lazy(() => import("./views/asset/main"));
const AssetScheduledMaintenance = lazy(
  () => import("./views/asset/scheduledMaintenance")
);
const AssetVehiclePreUseChecks = lazy(
  () => import("./views/asset/vehiclePreUseChecks")
);
const AssetShortTermHires = lazy(() => import("./views/asset/shortTermHires"));
const AssetInWorkshop = lazy(() => import("./views/asset/workshop"));
const AssetRecharges = lazy(() => import("./views/asset/recharges"));
const AssetReplacementProgram = lazy(
  () => import("./views/asset/replacementProgram")
);
const AssetTransfers = lazy(() => import("./views/asset/transfers"));
const AssetFuel = lazy(() => import("./views/asset/fuel"));

// Notification routes
const Notifications = lazy(() => import("./views/notifications"));
const Notification = lazy(() => import("./views/notifications/notification"));

// Transfer routes
const Transfers = lazy(() => import("./views/transfers"));
const TransferRequests = lazy(() => import("./views/transfers/open"));
const TransferApprovals = lazy(() => import("./views/transfers/approvals"));
const TransferHistory = lazy(() => import("./views/transfers/history"));

// replacement routes
const ReplacementProgramme = lazy(() => import("./views/replacementProgramme"));
const OrderTracker = lazy(
  () => import("./views/replacementProgramme/orderTracker")
);
const OrderArchive = lazy(
  () => import("./views/replacementProgramme/orderArchive")
);
const Specifications = lazy(
  () => import("./views/replacementProgramme/specifications")
);
const SpecificationsArchive = lazy(
  () => import("./views/replacementProgramme/specificationsArchive")
);

// help and support routes
const Help = lazy(() => import("./views/help"));
const Contacts = lazy(() => import("./views/help/contacts"));
const OtherContacts = lazy(() => import("./views/help/otherContacts"));
const Training = lazy(() => import("./views/help/training"));
const TrainingItem = lazy(() => import("./views/help/trainingItem"));
const BusinessJustification = lazy(
  () => import("./views/help/businessJustification")
);
const ShortTermHirePolicy = lazy(
  () => import("./views/help/shortTermHirePolicy")
);

const ResetUserPassword = lazy(() => import("./views/admin/resetUserPassword"));
const UserManagement = lazy(() => import("./views/admin/userManagement"));
const CronManagement = lazy(() => import("./views/admin/cronManagement"));
const MaintenanceManagement = lazy(
  () => import("./views/admin/maintenanceManagement")
);
const DocumentManagement = lazy(
  () => import("./views/admin/documentManagement")
);

const routes = [
  {
    path: "/menu",
    name: "Menu",
    element: <Menu />,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/",
    name: "Dashboard",
    element: <Dashboard />,
    menu: HomeIcon,
  },
  {
    path: "/account",
    name: "Account",
    element: <Account />,
  },
  {
    path: "/assets",
    name: "Your Fleet",
    menu: TruckIcon,
    children: [
      {
        path: "/assets/",
        name: "Your Fleet",
        element: <Fleet />,
      },
      {
        path: "/assets/:id",
        name: "Asset",
        element: <Asset />,
        children: [
          {
            path: "/assets/:id/",
            name: "Asset info",
            element: <AssetInfo />,
          },
          {
            path: "/assets/:id/scheduled-maintenance",
            name: "Asset scheduled maintenancy",
            element: <AssetScheduledMaintenance />,
          },
          {
            path: "/assets/:id/vehicle-pre-use-checks",
            name: "Asset vehicle pre-use checks",
            element: <AssetVehiclePreUseChecks />,
          },
          {
            path: "/assets/:id/in-workshop",
            name: "Asset in workshop",
            element: <AssetInWorkshop />,
          },
          {
            path: "/assets/:id/recharges",
            name: "Asset Recharges",
            element: <AssetRecharges />,
          },
          {
            path: "/assets/:id/short-term-hires",
            name: "Asset Short Term Hires",
            element: <AssetShortTermHires />,
          },
          {
            path: "/assets/:id/replacement-program",
            name: "Asset Replacement Program",
            element: <AssetReplacementProgram />,
          },
          {
            path: "/assets/:id/transfers",
            name: "Asset Transfers",
            element: <AssetTransfers />,
          },
          {
            path: "/assets/:id/fuel",
            name: "Asset Fuel",
            element: <AssetFuel />,
          },
        ],
      },
    ],
  },
  {
    path: "/vehicle-pre-use-checks",
    name: "Asset Pre-Use Checks",
    element: <VehiclePreUseChecks />,
    menu: ClipboardIcon,
  },
  {
    path: "/defect-management",
    name: "Defect Management",
    element: <DefectManagement />,
    menu: WarningIcon,
  },

  {
    path: "/scheduled-maintenance",
    name: "Scheduled Maintenance",
    element: <ScheduledMaintenance />,
    menu: CalendarIcon,
  },
  {
    path: "/recalls",
    name: "Recalls",
    element: <Recalls />,
    menu: BackArrowIcon,
  },
  {
    path: "/workshop",
    name: "In Workshop",
    element: <Workshop />,
    menu: WrenchIcon,
  },
  {
    path: "/notifications",
    name: "Notifications",
    menu: BellIcon,
    children: [
      {
        name: "Notifications",
        path: "/notifications/",
        element: <Notifications />,
      },
      {
        name: "Notification",
        path: "/notifications/:id",
        element: <Notification />,
      },
    ],
  },

  {
    path: "/breakdowns",
    name: "Breakdowns",
    element: <Breakdowns />,
    menu: PickupTruckIcon,
  },
  {
    path: "/short-term-hires",
    name: "Short Term Hires",
    element: <ShortTermHires />,
    menu: ClockIcon,
  },
  {
    path: "/recharges",
    name: "Recharges",
    element: <Recharges />,
    menu: CogIcon,
  },
  {
    path: "/fuel",
    name: "Fuel",
    element: <Fuel />,
    menu: PumpIcon,
  },
  {
    path: "/replacement-programme",
    name: "Replacement Programme",
    element: <ReplacementProgramme />,
    menu: CartIcon,
    children: [
      {
        name: "Order Tracker",
        path: "/replacement-programme/",
        element: <OrderTracker />,
      },
      {
        name: "Order Archive",
        path: "/replacement-programme/order-archive",
        element: <OrderArchive />,
      },
      {
        name: "Specifications",
        path: "/replacement-programme/specifications",
        element: <Specifications />,
      },
      {
        name: "Specifications Archive",
        path: "/replacement-programme/specifications-archive",
        element: <SpecificationsArchive />,
      },
    ],
  },
  {
    name: "Training Item",
    path: "/help-and-support/training/:slug",
    element: <TrainingItem />,
  },
  {
    path: "/help-and-support",
    name: "Help & Support",
    element: <Help />,
    menu: QuestionIcon,
    children: [
      {
        name: "Contacts",
        path: "/help-and-support/",
        element: <Contacts />,
      },
      {
        name: "Other Contacts",
        path: "/help-and-support/other-contacts",
        element: <OtherContacts />,
      },
      {
        name: "Training",
        path: "/help-and-support/training",
        element: <Training />,
      },
      {
        name: "Business Justification",
        path: "/help-and-support/business-justification",
        element: <BusinessJustification />,
      },
      {
        name: "Short Term Hire Policy",
        path: "/help-and-support/short-term-hire-policy",
        element: <ShortTermHirePolicy />,
      },
    ],
  },

  {
    path: "/briefs",
    name: "Briefs",
    children: [
      {
        name: "Briefs",
        path: "/briefs/",
        element: <Briefs />,
      },
      {
        name: "Brief",
        path: "/briefs/:id",
        element: <Brief />,
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    menu: WarningIcon,
    element: <Admin />,
    children: [
      {
        name: "Reset User Password",
        path: "/admin/",
        element: <ResetUserPassword />,
      },
      {
        name: "User Managment",
        path: "/admin/user-management",
        element: <UserManagement />,
      },
      {
        name: "Maintenance Management",
        path: "/admin/maintenance-management",
        element: <MaintenanceManagement />,
      },
      {
        name: "Document Management",
        path: "/admin/document-management",
        element: <DocumentManagement />,
      },
      {
        name: "Cron Job Management",
        path: "/admin/cron-job-management",
        element: <CronManagement />,
      },

      // {
      //   name: "Business Justification",
      //   path: "/help-and-support/business-justification",
      //   element: <BusinessJustification />,
      // },
      // {
      //   name: "Short Term Hire Policy",
      //   path: "/help-and-support/short-term-hire-policy",
      //   element: <ShortTermHirePolicy />,
      // },
    ],
  },
];
export default routes;
