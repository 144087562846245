import { Suspense, lazy, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import Loader from "./components/Loader";
import { ErrorBoundary } from "./utilities/errorBoundary";
import routes from "./routes";
import ScrollToTop from "./utilities/scrollToTop";
import { useAuth } from "./hooks/useAuth";

const LayoutUnauth = lazy(() => import("./components/Layout/Unauthorised"));
const LayoutAuth = lazy(() => import("./components/Layout/Authorised"));

// Unauthenticated forms
const Login = lazy(() => import("./views/unauthenticated/login"));
const Forgotten = lazy(() => import("./views/unauthenticated/forgotPassword"));
const Reset = lazy(() => import("./views/unauthenticated/resetPassword"));

const App = () => {
  const toast = useToast();
  const { feedback, status } = useAuth();

  useEffect(() => {
    feedback &&
      toast({
        title: feedback.message,
        status: feedback.alert,
        isClosable: true,
      });
  }, [toast, feedback]);

  return (
    <ErrorBoundary fallback="app crash">
      <ScrollToTop />
      <Suspense fallback="">
        {status === "is_authenticated" ? (
          <Authenticated />
        ) : status === "is_refreshing" ? null : (
          <Unauthenticated />
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;

export function Authenticated() {
  const element = useRoutes(routes);

  return (
    <LayoutAuth>
      <Suspense fallback={<Loader flat />}>{element}</Suspense>
    </LayoutAuth>
  );
}

export function Unauthenticated() {
  const { login, forgotPassword, resetPassword } = useAuth();

  const element = useRoutes([
    { path: "/", element: <Login onSubmit={login} /> },
    {
      path: "/forgotten-password",
      element: <Forgotten onSubmit={forgotPassword} />,
    },
    {
      path: "/reset-password",
      element: <Reset onSubmit={resetPassword} />,
    },
    // fallback route
    { path: "/*", element: <Login onSubmit={login} /> },
  ]);

  return (
    <LayoutUnauth>
      <Suspense fallback={<Loader flat />}>{element}</Suspense>
    </LayoutUnauth>
  );
}
