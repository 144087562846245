import { Component } from "react";

interface IErrorBoundaryProps {
  fallback?: string | React.ReactNode;
  card?: boolean;
}
interface IErrorBoundaryState {
  hasError: boolean;
}
export class ErrorBoundary extends Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log("error boundary", { error });
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const fallback = this.props.fallback || "Something went wrong.";
      // You can render any custom fallback UI
      const content =
        typeof fallback === "string" ? <h1>{fallback}</h1> : fallback;
      return this.props.card ? <div>{content}</div> : content;
    }

    return this.props.children;
  }
}
