import { extendTheme } from "@chakra-ui/react";
import { FC } from "react";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";

import Fonts from "./fonts";
import styles from "./styles";
import colors from "./colors";
import components from "./components";

const theme = extendTheme({
  fonts: {
    heading: "metaplus",
    body: "metaplus",
  },
  styles,
  colors,
  components,
});

const Theme: FC = ({ children }) => (
  <ChakraProvider theme={theme}>
    <CSSReset />
    <Fonts />
    {children}
  </ChakraProvider>
);

export default Theme;
