import { FunctionComponent } from "react";
import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  Icon,
  Center,
} from "@chakra-ui/react";

interface IPageHeadingProps extends HeadingProps {
  icon?: FunctionComponent<any>;
}
export const PageHeading = ({ icon, children }: IPageHeadingProps) => {
  return (
    <Heading as="h1" variant="page" display="flex" alignContent="center">
      <Center>{icon && <Icon as={icon} w={8} h={8} mr={2} />} </Center>
      {children}
    </Heading>
  );
};

interface ICardProps extends BoxProps {
  flat?: boolean;
}
export const Card = ({ flat = false, ...props }: ICardProps) => (
  <Box
    p={[4, 6, 8]}
    bg="white"
    borderRadius={1}
    boxShadow={flat ? "none" : "0 0 5px 0 rgba(219, 219, 219, 0.6)"}
    {...props}
  />
);

export const Panel = (props: BoxProps) => (
  <Box
    p={[4, 6, 8]}
    mt={[4, 6, 8]}
    bg="white"
    boxShadow="0 0 5px 0 rgba(219, 219, 219, 0.6)"
    {...props}
  />
);
