/** @jsxImportSource @emotion/react */

import { css, Global } from "@emotion/react";

import metaPlusWoff from "../assets/fonts/metaplus.woff";
import metaPlusWoff2 from "../assets/fonts/metaplus.woff2";
import metaPlusTtf from "../assets/fonts/metaplus.ttf";

import metaPlusMdWoff from "../assets/fonts/metaplus-medium.woff";
import metaPlusMdWoff2 from "../assets/fonts/metaplus-medium.woff2";
import metaPlusMdTtf from "../assets/fonts/metaplus-medium.ttf";

import metaPlusBoldWoff from "../assets/fonts/metaplus-bold.woff";
import metaPlusBoldWoff2 from "../assets/fonts/metaplus-bold.woff2";
import metaPlusBoldTtf from "../assets/fonts/metaplus-bold.ttf";

const Fonts = () => (
  <Global
    styles={css`
      @font-face {
        font-family: "metaplus";
        src: url(${metaPlusTtf});
        src: url(${metaPlusWoff2}) format("woff2"),
          url(${metaPlusWoff}) format("woff");
        font-display: swap;
      }
      @font-face {
        font-family: "metaplus";
        src: url(${metaPlusMdTtf});
        src: url(${metaPlusMdWoff2}) format("woff2"),
          url(${metaPlusMdWoff}) format("woff");
        font-weight: 500;
        font-display: swap;
      }
      @font-face {
        font-family: "metaplus";
        src: url(${metaPlusBoldTtf});
        src: url(${metaPlusBoldWoff2}) format("woff2"),
          url(${metaPlusBoldWoff}) format("woff");
        font-weight: 600;
        font-display: swap;
      }
    `}
  />
);

export default Fonts;
