import axios from "axios";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { AuthProvider } from "./hooks/useAuth";
import Theme from "./theme";

import App from "./App";
import "../node_modules/focus-visible/dist/focus-visible.min.js";

axios.defaults.baseURL = process.env.REACT_APP_API;
const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Theme>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Theme>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
