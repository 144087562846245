const colors = {
  primary: {
    50: "#E3E6EA",
    100: "#E2E8F0",
    200: "#286EFC",
    300: "#0450E9",
    400: "#033EB3",
    500: "#022c7e",
    600: "#02256C",
    700: "#03153f",
    800: "#011948",
    900: "#011236",
  },

  urgent: {
    50: "#F36D63",
    100: "#F25D52",
    200: "#F14D41",
    300: "#EF3C30",
    400: "#EE2C1E",
    500: "#e82011",
    600: "#C71B0F",
    700: "#A6170C",
    800: "#85120A",
    900: "#640E07",
  },

  complete: {
    50: "#62ED3F",
    100: "#50EB2A",
    200: "#40E816",
    300: "#3AD314",
    400: "#34BD12",
    500: "#2ea710",
    600: "#28900D",
    700: "#21780B",
    800: "#1A6009",
    900: "#144807",
  },

  required: {
    50: "#FBAA5F",
    100: "#FAA14D",
    200: "#FA983C",
    300: "#FA8E2A",
    400: "#F98518",
    500: "#f97a07",
    600: "#D56A05",
    700: "#B25805",
    800: "#8E4704",
    900: "#6B3503",
  },
};

export default colors;
