const Heading = {
  variants: {
    page: {
      fontSize: "3xl",
      color: "primary.700",
      fontWeight: 500,
      letterSpacing: "-0.54px",
      mb: [4, 6, 8],
    },
    section: {
      color: "primary.700",
      fontSize: "lg",
      fontWeight: 500,
      mb: [2, 3, 4],
    },
  },
};

export default Heading;
