import React, { FC, useState } from "react";
import axios, { AxiosResponse } from "axios";

const AUTH_TOKEN = "__vls_auth_token__";

interface IUser {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  logo: string;
  customer_id: number;
}

type ILoginResponse = [{ user: IUser }, { access_token: string }];

interface ICredentials {
  email: string;
  password: string;
}

function APIlogin(
  credentials: ICredentials
): Promise<AxiosResponse<ILoginResponse>> {
  return axios.post(`/login`, { ...credentials, device_name: "api" });
}

function APIrefreshSession(
  token: string
): Promise<AxiosResponse<ILoginResponse>> {
  return axios.post("/re-auth", null, {
    headers: { Authorization: `Bearer ${token}` },
  });
}

function APIlogout() {
  return axios.post(`/logout`);
}

function APIforgotPassword(
  email: string
): Promise<AxiosResponse<{ success: string }>> {
  return axios.post("/forgot-password", { email });
}

function APIresetPassword(
  newPassword: string,
  token: string
): Promise<AxiosResponse<{ success: string }>> {
  return axios.post("/reset-password", {
    token,
    password: newPassword,
    password_confirmation: newPassword,
  });
}

type TStatus =
  | "not_authenticated"
  | "is_refreshing"
  | "is_authenticating"
  | "is_authenticated";
type TFeedback = null | {
  message: string;
  alert: "error" | "info" | "success";
};

const AuthContext = React.createContext<{
  user: IUser | null;
  status: TStatus;
  feedback: TFeedback;
  logout: () => void;
  resetPassword: (args: any) => Promise<void>;
  forgotPassword: (args: any) => Promise<void>;
  login: (cred: ICredentials) => Promise<void>;
}>({
  user: null,
  status: "is_authenticating",
  feedback: null,
  logout: () => {},
  resetPassword: () => new Promise(() => {}),
  forgotPassword: () => new Promise(() => {}),
  login: () => new Promise(() => {}),
});

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [status, setStatus] = useState<TStatus>(() => "is_refreshing");
  const [feedback, setFeedback] = useState<TFeedback>(null);

  function clearSession() {
    if (axios.defaults?.headers?.common?.["Authorization"])
      axios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem(AUTH_TOKEN);
    setUser(null);
    setStatus("not_authenticated");
  }

  const setSession = React.useCallback((userDetails: IUser, token: string) => {
    if (token) {
      localStorage.setItem(AUTH_TOKEN, token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setUser(userDetails);
      setStatus("is_authenticated");
      setFeedback({
        message: "Successfully logged in",
        alert: "success",
      });
    } else clearSession();
  }, []);

  async function login(credentials: ICredentials) {
    try {
      setStatus("is_authenticating");
      const { data } = await APIlogin(credentials);
      const [{ user }, { access_token }] = data;
      setSession(user, access_token);
    } catch (error: any) {
      const serverError = error?.response?.data?.error;
      const networkError = error instanceof Error && error?.message;
      clearSession();
      setFeedback({
        message: serverError || networkError || "Login error",
        alert: "error",
      });
    }
  }

  async function logout() {
    try {
      await APIlogout();
      clearSession();
    } catch (error: any) {
      const serverError = error?.response?.data?.message;
      const networkError = error instanceof Error && error?.message;
      setFeedback({
        message: serverError || networkError || "Logout error",
        alert: "error",
      });
      return Promise.reject();
    }
  }

  async function forgotPassword({ email }: { email: string }) {
    try {
      const res = await APIforgotPassword(email);
      setFeedback({
        message: res?.data?.success,
        alert: "info",
      });
      return Promise.resolve();
    } catch (error: any) {
      const serverError = error?.response?.data?.error;
      const networkError = error instanceof Error && error?.message;
      setFeedback({
        message: serverError || networkError || "Forgotten password error",
        alert: "error",
      });
      return Promise.reject();
    }
  }

  async function resetPassword({
    newPassword,
    token,
  }: {
    newPassword: string;
    token: string;
  }) {
    try {
      const res = await APIresetPassword(newPassword, token);
      setFeedback({
        message: res?.data?.success,
        alert: "info",
      });
      return Promise.resolve();
    } catch (error: any) {
      const serverError = error?.response?.data?.data;
      const networkError = error instanceof Error && error?.message;
      setFeedback({
        message: serverError || networkError || "Reset password error",
        alert: "error",
      });
      return Promise.reject();
    }
  }

  React.useEffect(() => {
    async function refreshSession(token: string) {
      try {
        setStatus("is_refreshing");
        const { data } = await APIrefreshSession(token);
        const [{ user }, { access_token }] = data;
        if (user && access_token) {
          setSession(user, access_token);
          setStatus("is_authenticated");
        } else throw new Error();
      } catch (error: any) {
        const serverError = error?.response?.data?.message;
        const networkError = error instanceof Error && error?.message;
        clearSession();
        setFeedback({
          message: serverError || networkError || "Please login again",
          alert: "error",
        });
      }
    }

    const token = localStorage.getItem(AUTH_TOKEN);
    if (token) refreshSession(token);
    else setStatus("not_authenticated");
  }, [setSession]);

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        login,
        resetPassword,
        forgotPassword,
        status,
        feedback,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
