const styles = {
  global: {
    // styles for the `body`
    body: {
      bg: "#F9F9FA",
      color: "black",
    },
    // styles for the `a`
    a: {
      color: "primary.500",
      _hover: {
        textDecoration: "underline",
      },
    },
    ".chakra-form__required-indicator": {
      color: "#03153f !important",
      marginLeft: "1px !important",
    },
    table: {
      width: "100%",
    },
    td: {
      color: "#565656",
    },
  },
};

export default styles;
