const Button = {
  baseStyle: {
    borderRadius: 2,
    colorScheme: "primary",
  },
  variants: {
    icon: {
      bg: "transparent",
      color: "primary.700",
      _hover: {
        color: "required.500",
      },
      _focus: {
        color: "primary.900",
      },
      _active: {
        color: "required.800",
      },
      _disabled: {
        color: "gray.500",
        _hover: {
          color: "gray.500",
        },
      },
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
};

export default Button;
