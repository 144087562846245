import Button from "./buttton";
import Accordion from "./accordion";
import Stat from "./stat";
import Heading from "./heading";

const components = {
  Accordion,
  Button,
  Heading,
  Stat,
};
export default components;
